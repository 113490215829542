<template>
  <div class="mobileList">
    <div class="list-filter">
      <el-input v-model="keywords" prefix-icon="el-icon-search" size="small" @input="onInput" placeholder="输入商场名称或者ID进行搜索"></el-input>
    </div>
    <div class="list-table">
      <el-table v-if="projectList.length > 0" :data="projectList" :show-header="false">
        <el-table-column prop="projectID" sortable label="项目ID" width="50"></el-table-column>
        <el-table-column sortable label="项目名称">
          <template slot-scope="scope">
            <span class="title" @click="hrefDashboard(scope.row.projectID)">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="35">
          <template slot-scope="scope">
            <a class="dis_flex" @click="hrefDashboard(scope.row.projectID)">
              <i class="el-icon-arrow-right el-icon--right"></i>
            </a>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { getProjects } from '@/api/bimApi'
import BI_config from '@/utils/config'
import { mapState } from 'vuex'

export default {
  name: 'mobileList',
  data() {
    return {
      keywords: '',
      projectList: '',
      timer: null,
    }
  },
  computed: {
    ...mapState({
      projectID: state => state.projectID,
    }),
  },
  async created() {
    window.document.documentElement.setAttribute('data-theme', 'light')
    this.$loading({ lock: true, spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' })
    await this.getProjectList()
    this.$loading({ lock: false }).close()
  },
  methods: {
    async getProjectList() {
      let param = {
        keyword: this.keywords && this.keywords.trim(),
        paging: {
          index: 0,
          size: 5000,
        },
        sortings: [{ name: 'ProjectID', hint: 'asc' }],
      }
      let res = await getProjects(param)
      let projectList = res.data.list
      this.projectList = projectList
    },

    onInput() {
      if (this.timer != null) clearTimeout(this.timer)
      let self = this
      this.timer = setTimeout(() => {
        self.onSearch()
      }, 1000)
    },

    onSearch() {
      this.getProjectList()
    },

    hrefDashboard(projectID) {
      window.location.href = `${BI_config.SiteHost}${BI_config.dashboardProxy}/Mobile/${projectID}`
    },
  },
}
</script>

<style lang="scss" scoped>
.mobileList {
  .list-filter {
    width: 100%;
    padding-top: 10px;
    margin-bottom: 10px;
    .el-input {
      width: calc(100% - 20px);
      margin-left: 10px;
    }
  }
  .list-table {
    border-top: 1px solid #eee;
    .title {
      color: #409eff;
      display: inline-block;
    }
  }
}
</style>

<style lang="scss">
.mobileList {
  .list-filter {
    .el-input {
      .el-input__inner {
        border: 0;
        background: rgba(0, 0, 0, 0.05);
        line-height: 20px;
      }
    }
  }
  .list-table {
    .el-table {
      tr {
        .el-table_1_column_1 {
          color: #999;
          text-align: center;
        }
        .el-table_1_column_2 {
          color: #333;
        }
        .cell {
          padding-right: 0;
          padding-left: 5px;
          a {
            color: #409eff;
            .el-icon--right {
              margin-left: 2px;
            }
          }
          .el-button {
            padding: 0;
            color: #999;
          }
        }
      }
      td,
      th {
        padding: 12px 0;
      }
    }
  }
}
</style>
