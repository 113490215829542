import request from '@/utils/bimRequest'
/** 
获取项目列表 分页
**/
export function getProjects(data) {
  return request({
    url: '/Project/list',
    method: 'post',
    data: data,
  })
}
