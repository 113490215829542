import BI_config from '@/utils/config'
import store from '@/store'
import { getToken, hashCode } from '@/utils/auth'
import axios from 'axios'
import Vue from 'vue'
let myv = new Vue()

var service = axios.create({
  baseURL: BI_config.bimApi,
  timeout: 160000,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Accept-Language': 'zh-cn',
    'Mallcoo-BI-Source': 'BI2.0',
    'Mallcoo-BI-Authorization': 'mp_session=' + getToken() || '',
  },
})
service.interceptors.request.use(
  function(config) {
    config.cancelToken = new axios.CancelToken(cancel => {
      store.commit('pushToken', {
        cancelToken: cancel,
      })
    })
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    postTrace(response.headers)
    return response
  },
  error => {
    error.response && postTrace(error.response.headers)
    if (error.response) {
      let data = error.response.data
      if (data.code === 401) {
        location.href = `${BI_config.baseLogin}?callbackUrl=${encodeURIComponent(location.href)}`
      }
      let code = hashCode(data.message)
      if (!store.state.errorCounter.has(code)) {
        store.state.errorCounter.add(code)
        myv.$alert(data.message, '提示', {
          confirmButtonText: '确定',
          callback: action => {
            setTimeout(() => {
              store.state.errorCounter.delete(code)
            }, 500)
          },
        })
      }
    }
    return Promise.reject(error) // 返回接口返回的错误信息
  }
)

let postTrace = headers => {
  axios
    .post(
      `${BI_config.baseApi}/api/trace`,
      { referer: window.location.href },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'R-TraceId': headers['r-traceid'],
        },
      }
    )
    .then(res => {})
}

export default function request(opts, type) {
  let opts1 = { method: type, ...opts }
  return new Promise((resolve, reject) => {
    return service(opts1)
      .then(res => {
        resolve(res.data)
      })
      .catch(e => {
        console.error(e)
        reject(e)
      })
  })
}
